import React from 'react';
import { graphql, Link } from 'gatsby'
import Footer from '../components/footer'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Icon } from 'antd';

import '../styles/global.scss'

export default ({ data, pageContext }) => {
    const { title, slug, date } = data.markdownRemark.frontmatter
    const __html = data.markdownRemark.html
    const { prev, next } = pageContext

    return (
        <Layout position='top' currentPage={slug}>
            <SEO title="Shalee, l'agent digital équestre" keywords={[`Shalee`,`Application`,`Mobile`,`Cheval`,`Chevaux`,`Club`,`Equestre`,`Equitation`,`Cavalier`]}/>
            <div style={{paddingTop: '100px', margin: 'auto', maxWidth:'1024px'}}> 
                <h1 style={{transform: 'Uppercase', textAlign: "center"}}>{title}</h1>  
                {/* <p>{date}</p>   */}
                <div style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html }} />  
                <Row type='flex' gutter={18} style={{textAlign: "center", margin: '30px 0'}}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    {next && (
                        <Link to={next.frontmatter.slug}><Icon type='left'/> {next.frontmatter.title}</Link>
                    )}
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Link to='/tutoriels' style={{ textAlign: 'center'}}>Tous les tutoriels</Link> 
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    {prev && (
                        <Link to={prev.frontmatter.slug}>{prev.frontmatter.title} <Icon type='right'/></Link>
                    )}
                    </Col>
                </Row>
                
            </div>
            <Footer/>
        </Layout>
    );
};

export const query = graphql`
    query($slug: String!){
        markdownRemark(frontmatter: { slug: { eq: $slug } }){
            html
            frontmatter {
                slug
                title
                date(formatString: "DD MMMM, YYYY")
            }
        }
    }
`
